 {
   {
    /* @import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap"); */
  }
}
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-ExtraLight-BF65557b671cf51.otf");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-Thin-BF65557b6722e69.otf");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-Light-BF65557b670066a.otf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-Regular-BF65557b67059a7.otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-Medium-BF65557b6724eb8.otf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-SemiBold-BF65557b6736b0c.otf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-Bold-BF65557b670a6bf.otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "br-firma";
  src: url("./assets/font/br-firma-font/BRFirma-Black-BF65557b6717fec.otf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

*,
body {
  font-family: "br-firma", sans-serif !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 24px !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  fill: #fff !important;
}

.css-1nrm8k5-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root-MuiTablePagination-select::before {
  display: none !important;
}

.MuiFilledInput-root {
  font-size: 12px !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 18px !important;
  font-size: 14px;
}

/* Remove default arrows in number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.css-l5cl78-MuiModal-root-MuiPopover-root-MuiMenu-root
  .MuiPaper-root
  .MuiMenu-list {
  padding: 0px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 640px;
  border-radius: 10;
  box-shadow: 0 2px 6px 0 #e0e0e0;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  fill: #003163 !important;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.css-3ce5un-MuiModal-root-MuiDialog-root .MuiDialog-paper {
  z-index: 95;
}

.css-1tde54n-MuiDrawer-docked .MuiDrawer-paper {
  width: auto;
}
.rsw-toolbar {
  background: transparent;
}
.rsw-ce {
  height: 24rem;
  overflow-y: auto;
}
.ql-editor {
  min-height: 20rem;
  max-height: 40rem;
}
.ql-toolbar {
  border-radius: 10px 10px 0px 0px;
  background: #eeeeee;
}
.ql-container {
  border-radius: 0px 0px 10px 10px;
}

.jodit .jodit-wysiwyg ol, 
.jodit .jodit-wysiwyg ul  {
  list-style-type: decimal; /* Ensures numbers appear */
  margin-left: 30px; /* Optional: Adjust left margin */
}